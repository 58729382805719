import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { main_routes } from "../components/routes/routes";
import styles from "./mainPage.module.scss";
import { useSelector } from "react-redux";
import { PositionApproveView, roleMenuRouteConfig } from "./auth/roles";
import { urls } from "../config/urls";
import { httpClient } from "../config/config";

export default function MainPage() {
    const [unreadCount, setUnReadCount] = useState(0);
    const [changes, setChanges] = useState(0);
    const roles = useSelector((state) => state.auth.roles);

    useEffect(() => {
        httpClient({ url: `${urls.demandOrders}/get-unreads-count`, type: "demand", dontShowError: true }).then((res) =>
            setUnReadCount(res.data?.data)
        );
    }, []);

    useEffect(() => {
        if (roles?.includes(PositionApproveView)) {
            httpClient({ url: `${urls.employee_position}/changes`, dontShowError: true }).then((res) =>
                setChanges(res.data?.data?.recordCount)
            );
        }
    }, [roles]);

    function filterMenuItemsByRole(userRoles, menuItems) {
        return menuItems.filter((item) => {
            const isRoleRestricted = Object.keys(roleMenuRouteConfig).some((role) => roleMenuRouteConfig[role].includes(item.key));
            return !isRoleRestricted || userRoles.some((role) => roleMenuRouteConfig[role]?.includes(item.key));
        });
    }
    const filteredMenuItems = filterMenuItemsByRole(roles, main_routes);

    return (
        <div className={styles.main_page}>
            {filteredMenuItems.map((item) => (
                <Link to={item.key} key={item.key}>
                    <div className={styles.main_page_card}>
                        <div className={styles.main_page_card_icon}>{item.icon}</div>
                        <div className={styles.main_page_card_text}>
                            {item.name}{" "}
                            <span style={{ color: "red", fontWeight: 500 }}>
                                {item.key == "/demand-order" && `(${unreadCount})`} {item.key == "/position-approve" && `(${changes})`}
                            </span>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}
