import React from "react";
import {
    AlignVerticalDistributeCenter,
    BarChart2,
    BookOpenCheck,
    Box,
    Boxes,
    CopyCheck,
    FileType,
    Home,
    LayoutList,
    PackageSearch,
    Ruler,
    Settings2,
    ShieldHalf,
    Users2,
} from "lucide-react";

import MainPage from "../../pages/MainPage";
import Login from "../../pages/auth/Login";
import Employees from "../../pages/employees/Employees";
import MenuList from "../common/MenuList/MenuList";
import MainResource from "../../pages/MainResource/MainResource";
import { DocSettingsIcon, DocsIcon } from "../common/SvgIcons";

const Users = React.lazy(() => import("../../pages/users/Users"));
const Products = React.lazy(() => import("../../pages/products/Products"));
const Structure = React.lazy(() => import("../../pages/structure/Structure"));
const StaffUnit = React.lazy(() => import("../../pages/stateUnit/StaffUnit"));
const MeasureUnits = React.lazy(() => import("../../pages/measureUnits/MeasureUnits"));
const Position = React.lazy(() => import("../../pages/positions/Position"));
const ApprovePosition = React.lazy(() => import("../../pages/ApprovePosition/ApprovePosition"));
const RequisitionDocStatus = React.lazy(() => import("../../pages/requisitionDocStatus/RequisitionDocStatus"));
const RequisitionDocType = React.lazy(() => import("../../pages/requisitionDocType/RequisitionDocType"));
const DemandOrder = React.lazy(() => import("../../pages/DemandOrder/DemanOrder"));
const EquipmentType = React.lazy(() => import("../../pages/Equipments/EquipmentType/EquipmentType"));
const EquipmentCondition = React.lazy(() => import("../../pages/Equipments/EquipmentCondition/EquipmentCondition"));
const EquipmentLocation = React.lazy(() => import("../../pages/Equipments/EquipmentLocation/EquipmentLocation"));
const Equipments = React.lazy(() => import("../../pages/Equipments/Equipments/Equipments"));
const OrderPriority = React.lazy(() => import("../../pages/OrderPriority/OrderPriority"));

//  ESD ROUTES
const DeliveryForm = React.lazy(() => import("../../pages/ESD/Settings/DeliveryForm/DeliveryForm"));
const DocumentKind = React.lazy(() => import("../../pages/ESD/Settings/DocumentKind/DocumentKind"));
const DocumentType = React.lazy(() => import("../../pages/ESD/Settings/DocumentType/DocumentType"));
const ExternalPerson = React.lazy(() => import("../../pages/ESD/Settings/ExternalPerson/ExternalPerson"));
const ExternalStructure = React.lazy(() => import("../../pages/ESD/Settings/ExternalStructure/ExternalStructure"));
const Nomenclature = React.lazy(() => import("../../pages/ESD/Settings/Nomenclature/Nomenclature"));
const Document = React.lazy(() => import("../../pages/ESD/Document/Document"));
const DerkenarTemplate = React.lazy(() => import("../../pages/ESD/Settings/DerkenarTemplate/DerkenarTemplate"));
const DerkenarPermission = React.lazy(() => import("../../pages/ESD/Settings/DerkenarPermission/DerkenarPermission"));
const Curation = React.lazy(() => import("../../pages/ESD/Settings/Curation/Curation"));
const Country = React.lazy(() => import("../../pages/countries/Country"));
const Brand = React.lazy(() => import("../../pages/brand/Brand"));
const Vendor = React.lazy(() => import("../../pages/vendor/Vendor"));

export const routes = [
    {
        name: "Əsas",
        key: "",
        icon: <Home />,
        element: <MainPage />,
        children: [],
        childrenForMenu: false,
    },
    {
        name: "Soraqçalar",
        key: "settings",
        icon: <Settings2 />,
        element: <MenuList />,
        children: [
            {
                name: "Vəzifələr",
                key: "position",
                element: <Position />,
                children: [],
                icon: <ShieldHalf />,
            },
            {
                name: "Tələbnamə sənədlərinin statusları",
                key: "requisition-doc-status",
                icon: <BarChart2 />,
                element: <RequisitionDocStatus />,
                children: [],
            },
            {
                name: "Tələbnamə sənəd növləri",
                key: "requisition-doc-type",
                icon: <FileType />,
                element: <RequisitionDocType />,
                children: [],
            },
            {
                name: "Məhsullar",
                key: "products",
                icon: <PackageSearch />,
                element: <Products />,
                children: [],
            },
            {
                name: "Ölçü vahidləri",
                key: "measure-units",
                icon: <Ruler />,
                element: <MeasureUnits />,
                children: [],
            },
            {
                name: "Vəsaitin növü",
                key: "equipment-type",
                element: <EquipmentType />,
                children: [],
            },
            {
                name: "Vəsaitin vəziyyəti",
                key: "equipment-condition",
                element: <EquipmentCondition />,
                children: [],
            },
            {
                name: "Vəsaitin yerləşmə yeri",
                key: "equipment-location",
                element: <EquipmentLocation />,
                children: [],
            },
            {
                name: "Vəsaitlərin siyahısı",
                key: "equipments",
                element: <Equipments />,
                children: [],
            },
            {
                name: "Prioritetlər",
                key: "order-priority",
                element: <OrderPriority />,
                children: [],
            },
            {
                name: "Ölkələr",
                key: "countries",
                element: <Country />,
                children: [],
            },
            {
                name: "Markalar",
                key: "brands",
                element: <Brand />,
                children: [],
            },
            {
                name: "Vendorlar",
                key: "vendor",
                element: <Vendor />,
                children: [],
            },
        ],
        childrenForMenu: false,
    },
    {
        name: "ESD Soraqçalar",
        key: "esd-settings",
        icon: <DocSettingsIcon />,
        element: <MenuList />,
        children: [
            {
                name: "Çatdırılma forması",
                key: "delivery-form",
                element: <DeliveryForm />,
                children: [],
            },
            {
                name: "Sənəd növü",
                key: "document-kind",
                element: <DocumentKind />,
                children: [],
            },
            {
                name: "Sənəd tipi",
                key: "document-type",
                element: <DocumentType />,
                children: [],
            },
            {
                name: "Nomenklatura",
                key: "nomenklature",
                element: <Nomenclature />,
                children: [],
            },
            {
                name: "Kənar qurum",
                key: "external-structure",
                element: <ExternalStructure />,
                children: [],
            },
            {
                name: "Kənar qurum əməkdaşı",
                key: "external-person",
                element: <ExternalPerson />,
                children: [],
            },
            {
                name: "Dərkənar şablonları",
                key: "derkenar-template",
                element: <DerkenarTemplate />,
                children: [],
            },
            {
                name: "Kurasiya",
                key: "curation",
                element: <Curation />,
                children: [],
            },
            {
                name: "Dərkənara göndərməyə icazə",
                key: "derkenar-permission",
                element: <DerkenarPermission />,
                children: [],
            },
        ],
        childrenForMenu: false,
    },
    {
        name: "İstifadəçilər",
        key: "/users",
        icon: <Users2 />,
        element: <Users />,
        children: [],
        childrenForMenu: false,
    },
];

export const main_routes = [
    {
        name: "Struktur",
        key: "/structure",
        icon: <AlignVerticalDistributeCenter />,
        element: <Structure />,
        children: [],
    },
    {
        name: "Ştat vahidləri",
        key: "/staff-unit",
        icon: <Boxes />,
        element: <StaffUnit />,
        children: [],
    },
    {
        name: "Əməkdaşlar",
        key: "/employees",
        icon: <LayoutList />,
        element: <Employees />,
        children: [],
    },
    {
        name: "Tələbnamə",
        key: "/demand-order",
        icon: <BookOpenCheck />,
        element: <DemandOrder />,
        children: [],
    },
    {
        name: "Əsas vəsaitlər",
        key: "/main-resource",
        icon: <Box />,
        element: <MainResource />,
        children: [],
    },
    {
        name: "Təsdiq gözləyən vəzifə dəyişikliyi",
        key: "/position-approve",
        icon: <CopyCheck />,
        element: <ApprovePosition />,
        children: [],
    },
    {
        name: "Sənədlər",
        key: "document",
        icon: <DocsIcon />,
        element: <Document />,
        children: [],
    },
];
export const auth = [
    {
        name: "",
        key: "/login",
        element: <Login />,
        icon: <></>,
        children: [],
    },
];
