import { Form, Input, Button, message } from "antd";
import styles from "./login.module.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { auth_url } from "../../config/config";
import Cookies from "js-cookie";
import ApfLogoCover from "../../assets/images/new_logo_cover.png";
import { getUserInfo } from "../../utils/getUserInfo";

const Login = () => {
    const navigate = useNavigate();

    const onFinish = (values) => {
        axios
            .post(auth_url, { username: values.username, password: values.password })
            .then((res) => {
                Cookies.set("apf_access_token", res.data.accessToken);
                Cookies.set("apf_refresh_token", res.data.refreshToken);
                getUserInfo(res.data.accessToken);
                navigate("/");
            })
            .catch(() => {
                message.error("İstifadəçi adı və ya şifrə yanlışdır");
            });
    };

    return (
        <div className={styles.main_login_container}>
            <div className={styles.login_right}>
                <div>
                    <img src={ApfLogoCover} />
                </div>
                <Form className={styles.login_form} layout="vertical" onFinish={onFinish}>
                    <div className={styles.form_area}>
                        <Form.Item
                            name="username"
                            label="İstifadəçi adı"
                            rules={[{ required: true, message: "İstifadəçi adını daxil edin!" }]}
                        >
                            <Input style={{ width: "100%" }} />
                        </Form.Item>

                        <Form.Item name="password" label="Şifrə" rules={[{ required: true, message: "Şifrəni daxil edin!" }]}>
                            <Input.Password visibilityToggle={false} className={styles.custom_password_item} />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" className={styles.login_form_button}>
                            Daxil ol
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default Login;
