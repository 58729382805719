import axios from "axios";
import { store } from "../redux/store";
import { loading, ready } from "../redux/slicers/loaderSlice";
import Cookies from "js-cookie";
import { message } from "antd";

let requestCounter = 0;
let base_url = "https://apfqrup.com/";
export const auth_url = "https://apfqrup.com/auth/api/auth/login";

export const httpClient = async ({ url, method, data, headers = {}, type, dontShowError, responseType }) => {
    const accessToken = Cookies.get("apf_access_token");
    // const refreshToken = Cookies.get("apf_refresh_token");

    let fullUrl = base_url;
    switch (type) {
        case "backend":
            fullUrl += "backend/";
            break;
        case "auth":
            fullUrl += "auth/";
            break;
        case "demand":
            fullUrl += "demand/";
            break;
        case "esd":
            fullUrl += "esd/";
            break;
        default:
            fullUrl += "backend/";
    }

    const config = {
        method: method,
        url: fullUrl + url,
        data: data,
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
            ...headers,
        },
    };

    if (responseType) {
        config.responseType = responseType;
    }

    let loadingTimeout;
    try {
        requestCounter++;
        loadingTimeout = setTimeout(() => {
            store.dispatch(loading());
        }, 300);

        const response = await axios(config);
        clearTimeout(loadingTimeout);

        if (response?.config?.method != "get") message.success("Əməliyyat uğurla icra olundu!");
        return response;
    } catch (error) {
        clearTimeout(loadingTimeout);
        if (error?.response?.status === 401) {
            Cookies.remove("apf_refresh_token");
            Cookies.remove("apf_access_token");
            window.location.replace("/login");
        }
        if (!dontShowError) message.error(error?.response?.data?.message);
        throw error;
    } finally {
        requestCounter--;
        if (requestCounter === 0) {
            store.dispatch(ready());
        }
    }
};
